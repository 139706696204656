import {isAfter, isBefore, isSameDay, parse, format, differenceInDays} from "date-fns";
import store from '@/store'
import DaysOfWeek from "../constants/DaysOfWeek";

const dateFormat = 'yyyy-MM-dd';
const timeFormat = 'HH:mm:ss';
const dateTimeFormat = dateFormat + ' ' + timeFormat;
const isoDateTimeFormat = dateFormat + "'T'" + timeFormat;

export function parseStringToDate(string) {
    if (/^\d{2}:\d{2}:\d{2}\.\d{1,3}$/.test(string)) {
        return parse(string, 'HH:mm:ss.SSS', new Date())
    } else if (/^\d{2}:\d{2}:\d{2}$/.test(string)) {
        return parse(string, 'HH:mm:ss', new Date())
    } else if (/^\d{4}$/.test(string)) {
        return parse(string, 'HHmm', new Date())
    } else if (/^\d{2}:\d{2}$/.test(string)) {
        return parse(string, 'HH:mm', new Date())
    } else if (/^\d{3}$/.test(string)) {
        return parse(string, 'Hmm', new Date())
    } else if (/^\d:\d{2}$/.test(string)) {
        return parse(string, 'H:mm', new Date())
    } else if (/^\d{2}$/.test(string)) {
        return parse(string, 'HH', new Date())
    } else if (/^\d$/.test(string)) {
        return parse(string, 'H', new Date())
    }  else {
        throw Error("unable to parse string " +  string)
    }
}

export function utcStringToDate(utcString) {
    if (!/z$/i.test(utcString)) {
        utcString += 'Z'
    }
    return new Date(utcString)
}

export function formatDateAsDay(date) {
    return date ? format(date, dateFormat) : date
}

export function parseStringToDateTime(string) {
    return string ? parse(string, dateTimeFormat, new Date()) : string
}

export function formatDateTimeToString(date) {
    return date ? format(date, dateTimeFormat) : date
}

export function formatDateTimeToISOString(date) {
    return date ? format(date, isoDateTimeFormat) : date
}

export function formatDateAsTime(date) {
    return date ? format(date, timeFormat) : date
}

export function parseStringToTime(string) {
    return parseStringToDate(string).toTimeString().slice(0, 5)
}

export function ignoreTime(date) {
    return date.setHours(0, 0, 0, 0)
}

export function isAllowedParameterDay(selectedDay, parameter) {
    return differenceInDays(ignoreTime(new Date()), ignoreTime(selectedDay)) <= parameter
}

export function sortByDate(date1, date2, isDesc) {
    if (isSameDay(date1, date2)) {
        return 0
    } else if (!isDesc[0]) {
        return isBefore(date1, date2) ? -1 : 1
    } else {
        return isAfter(date1, date2) ? -1 : 1
    }
}

export function applyDateFormat(date) {
    if (!date) {
        return date
    }
    
    const dateFormat = store.getters["parameterModule/getDateFormat"]
    switch (typeof date) {
        case "object":
        case "Date":
            return format(date, dateFormat)
        case "string":
            return format(new Date(date), dateFormat)
        default:
            return date;
    }
}

export function applyDateTimeFormat(date) {
    if (!date) {
        return date
    }
    
    const dateFormat = store.getters["parameterModule/getDateFormat"]
    const dateTimeFormat = dateFormat + ' HH:mm'
    switch (typeof date) {
        case "object":
        case "Date":
            return format(date, dateTimeFormat)
        case "string":
            return format(new Date(date), dateTimeFormat)
        default:
            return date;
    }
}

export function applyTimeFormat(date) {
    if (!date) {
        return date
    }
    const timeFormat = 'HH:mm'
    switch (typeof date) {
        case "object":
        case "Date":
            return format(date, timeFormat)
        case "string":
            return format(new Date(date), timeFormat)
        default:
            return date;
    }
}

export function convertDateToWeekday(date) {
// .getDay() return a index, 0 = sunday, 1 = monday, etc
    switch (date?.getDay()) {
        case 0:
            return DaysOfWeek.SUNDAY
        case 1:
            return DaysOfWeek.MONDAY
        case 2:
            return DaysOfWeek.TUESDAY
        case 3:
            return DaysOfWeek.WEDNESDAY
        case 4:
            return DaysOfWeek.THURSDAY
        case 5:
            return DaysOfWeek.FRIDAY
        case 6:
            return DaysOfWeek.SATURDAY
        default:
            return null
    }
}

export function getIndexFromWeekDay(weekday) {
    const days = [DaysOfWeek.MONDAY, DaysOfWeek.TUESDAY, DaysOfWeek.WEDNESDAY, DaysOfWeek.THURSDAY, DaysOfWeek.FRIDAY, DaysOfWeek.SATURDAY, DaysOfWeek.SUNDAY]
    return days.indexOf(weekday.toUpperCase())
}

export function orderByWeekdays(items, weekdayOfItem) {
    return items.sort((a, b) => {
        return getIndexFromWeekDay(weekdayOfItem(a)) - getIndexFromWeekDay(weekdayOfItem(b))
    })
}

export function splitHoursToDuration(hours) {
    return splitMinutesToDuration(hours * 60)
}

export function splitMinutesToDuration(minutes) {
    return splitMilliSeconds(minutes * 60 * 1000)
}

export function splitMilliSeconds(milliseconds) {
    if (milliseconds === 0) {
        return {
            hours: 0,
            minutes: 0,
            seconds: 0
        }
    }

    const oneHour = 60 * 60 * 1000
    const hoursMod = milliseconds % oneHour
    const hours = (milliseconds - hoursMod) / oneHour

    const oneMinute = 60 * 1000
    const minutesMod = hoursMod % oneMinute
    const minutes = (hoursMod - minutesMod) / oneMinute
    
    const oneSecond = 1000
    const secondsMod = minutesMod % oneSecond
    const seconds = (minutesMod - secondsMod) / oneSecond

    return {
        hours,
        minutes,
        seconds
    }
}

export function getTimeZone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone
}