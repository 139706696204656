import axiosInstance from "@/store/axios.instance";

export default {
    namespaced: true,
    state() {
        return {

        }
    },

    actions: {
        fetchPlannedTasksForTask({ rootGetters }, taskId) {
            return axiosInstance.get(`/tasks/${taskId}/planned-tasks`, rootGetters["authModule/getUrlConfig"])
            .then(result => result?.data)
        },
        removeAssignmentDetails({ rootGetters }, id) {
            return axiosInstance.delete(`/tasks/planned-tasks/${id}/unassign`, rootGetters["authModule/getUrlConfig"])
            .then(result => result?.data)
        },
        cancelPlannedTask({ rootGetters }, id) {
            return axiosInstance.put(`/tasks/planned-tasks/${id}/cancel`, null, rootGetters["authModule/getUrlConfig"])
            .then(result => result?.data)
        },
        
    }
}