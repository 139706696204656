const ModuleType = Object.freeze({
    CLIENT_PROJECTMANAGEMENT: 'client_projectmanagement',
    EMPLOYEE_MANAGEMENT: 'employee_management',
    TIME_REGISTRATION: 'time_registration',
    INVOICE: 'invoice',
    LOCATION_REGISTRATION: 'location_registration',
    PLANNING: 'planning',
    AUTO_GENERATION: 'auto_generation'
});

export default ModuleType